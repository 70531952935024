<style lang="less" scoped>
.selectedFile {
  border: 2px solid #ff9900;
}
</style>
<template>
  <div>
    <Modal v-model="show" width="800">
      <p slot="header" class="text-center">设置电子屏反馈素材</p>
      <div>
        <div class="m-b-10 title">选择以下反馈随材进行设置</div>
        <Row :gutter="8" class="m-b-10">
          <i-col span="6" class="m-b-10" v-for="item in feedbackPicList" :key="item.id"
            :class="selectedFileIds.includes(item.id) ? 'selectedFile' : null">

            <a href="javascript:;" @click="handelChoiceFile(item)">
              <div v-if="item.mimeType.includes('video') || item.mimeType.includes('audio')">
                <video :src="item.filePath" width="100%">
                  您的浏览器不支持 video 标签。
                </video>
              </div>
              <img v-else :src="item.filePath" width="100%" />
            </a>
            <div class="m-t-5 text-center">
              <a :href="item.filePath" target="_blank">查看原画</a>
            </div>
          </i-col>
        </Row>
        <div>
          <Checkbox v-model="setSameMaterialOtherTaskitemAttach">同步设置相关电子屏</Checkbox>
        </div>
      </div>
      <div slot="footer">
        <Button type="text" @click="show = false">取消</Button>
        <Button type="primary" :disabled="selectedFileIds.length === 0" :loading="btnLoading"
          @click="onSubmit">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getTaskFeedbackAttachList, setTaskitemAttach } from '@/api/msp/taskother'
export default {
  data () {
    return {
      show: false,
      feedbackPicList: [],
      selectedFileIds: [],
      taskId: null,
      taskitemId: null,
      setSameMaterialOtherTaskitemAttach: false,
      btnLoading: false
    }
  },
  methods: {
    init (data) {
      this.getTaskFeedbackPic(data.taskId)
      this.taskId = data.taskId
      this.taskitemId = data.taskitemId
      this.setSameMaterialOtherTaskitemAttach = false
      this.selectedFileIds = []
      this.show = true
    },
    getTaskFeedbackPic (taskId, fileId = undefined) {
      this.feedbackPicList = []
      getTaskFeedbackAttachList({ taskId, fileId }).then(res => {
        if (res && !res.errcode) {
          this.feedbackPicList = res
        }
      })
    },
    handelChoiceFile (file) {
      if (this.selectedFileIds.includes(file.id)) {
        this.selectedFileIds.splice(this.selectedFileIds.indexOf(file.id), 1)
      } else {
        this.selectedFileIds.push(file.id)
      }
    },
    onSubmit () {
      if (this.selectedFileIds.length === 0) {
        this.$Notice.error({ desc: '请至少选择一个素材进行设置' })
        return false
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定信息无误，并提交？',
        onOk: () => {
          this.btnLoading = true
          const postData = {
            attathIds: JSON.stringify(this.selectedFileIds),
            taskId: this.taskId,
            setSameMaterialOtherTaskitemAttach: this.setSameMaterialOtherTaskitemAttach,
            taskitemId: this.taskitemId
          }
          setTaskitemAttach(postData).then(res => {
            if (res && res.errcode === 0) {
              this.selectedFileIds = []
              this.setSameMaterialOtherTaskitemAttach = false
              // this.$Notice.success({ desc: '操作成功' })
              this.$emit('refData')
            }
          }).finally(() => {
            this.btnLoading = false
            this.show = false
          })
        }
      })
    }
  }
}
</script>
